import React from "react";

const Versioning = (props) => (
  <p
    className={
      props.className
        ? props.className + " text-center"
        : " versione text-center"
    }
  >
    Versione 2.25.02.07.0001
  </p>
);

export default React.memo(Versioning);
